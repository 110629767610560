<template>
  <section>
    <v-overlay :value="carregandoDados">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <calendario-programacao :programacoes="programacoesRegiaoResumo" @coletarClickTabela="coletarClickTabela"
      @detalhesDistribuicao="detalhesDistribuicao" />
    <form-programacao-regiao v-model="abrirFormProgramacaoRegiao" :params="paramsModalEdicaoProgramacao"
      @atualizar="$emit('atualizar')" />
    <v-dialog v-model="detalhar">
      <detalhes-programacao :detalhes="detalhes" :title="title" :headers="headersDetalhes" :download="download"
        @close="closeModal()" />
    </v-dialog>
    <v-dialog v-model="modalSelecionaCota">
      <seleciona-programacao :dialog="modalSelecionaCota" :programacoes="programacoesPai" @selecionaCota="selecionaCota"
        @close="closeModalSelecionaCota" />
    </v-dialog>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormProgramacaoRegiao from './FormProgramacaoRegiao'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'
import CalendarioProgramacao from '../CalendarioProgramacao.vue'
import SelecionaProgramacao from '../SelecionaProgramacao.vue'

import { contabilizadores, pesquisa } from '@/mixins/cotas.js'

export default {
  name: 'CalendarioProgramacaoRegiao',
  mixins: [contabilizadores, pesquisa],
  components: {
    FormProgramacaoRegiao,
    DetalhesProgramacao,
    CalendarioProgramacao,
    SelecionaProgramacao,
  },

  data() {
    return {
      carregandoDados: false,
      abrirFormProgramacaoRegiao: false,
      modalSelecionaCota: false,
      paramsModalEdicaoProgramacao: {
        formTitle: 'Nova programação',
        dataSelecionada: '',
        public_id: '',
      },
      detalhes: [],
      title: {},
      download: {
        type: '',
        public_id: '',
      },
      detalhar: false,
      headersDetalhes: [
        {
          text: 'Veículo',
          value: 'veiculo',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          text: 'Liberado Em',
          value: 'saida_do_patio',
        },
        {
          text: 'Tempo em patio',
          value: 'tempo_em_patio',
        },
        {
          text: 'Finalizado Em',
          value: 'chegada_terminal',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Produto',
          value: 'produto',
        },
        {
          text: 'Sub Produto',
          value: 'sub_produto',
        },
        {
          text: 'Nota fiscal',
          value: 'num_nota_fiscal',
        },
        {
          text: 'Quantidade (KG)',
          value: 'quantidade',
        },
        {
          text: 'Embalagem',
          value: 'embalagem',
        },
        {
          text: 'Modalidade',
          value: 'modalidade',
        },
        {
          text: 'Eixos',
          value: 'eixos',
        },
      ],
      programacoesPai: [],
      col: null,
    }
  },
  computed: {
    ...mapState('programacao', ['params', 'programacoesRegiaoResumo']),

    itemClass() {
      return (item) => {
        if (typeof item['info'] !== 'object') {
          return item['info'].toLowerCase()
        }
      }
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('programacao', [
      'getProgramacoesRegiaoResumo',
      'getProgramacaoDetalhe',
      'getProgramacoesFornecedorSimple'
    ]),

    extractFiltersFromParams(params = null) {
      params = params == null ? this.params : params
      const filtros = this.pesquisa(params)
      if (!filtros.data_inicio) {
        filtros.data_inicio = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).setDate(
            new Date().getDate() - new Date().getDay()
          )
        )
          .toISOString()
          .split('T')[0]
      }
      return filtros
    },
    async atualizarResumoProgramacaoRegiao(params = null) {
      try {
        const filtros = this.extractFiltersFromParams(params)
        this.carregandoDados = true
        await this.getProgramacoesRegiaoResumo(filtros)
        this.carregandoDados = false
      } catch (error) {
        error.response &&
          (error.response.status == 403 || error.response.status == 404)
          ? this.errorMessage(
            'Empresa não possui acesso às programações de região.'
          )
          : this.errorMessage(
            'Erro ao atualizar resumo. Tente novamente ou contacte o suporte.'
          )
        this.carregandoDados = false
      }
    },
    async closeModal() {
      this.detalhar = false
      this.detalhes = []
      this.title = {}
    },
    async detalhesDistribuicao(item) {
      try {
        this.carregandoDados = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Regiao',
          public_id: item.PUBLIC_ID,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.detalhar = true
        this.carregandoDados = false
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregandoDados = false
      }
    },
    async detalhesDistribuicaoCliente(item) {
      try {
        this.carregandoDados = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Regiao',
          public_id: item.public_id,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.download.type = 'Regiao'
        this.download.public_id = item.public_id
        this.detalhar = true
        this.carregandoDados = false
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregandoDados = false
      }
    },
    selecionaCota(item) {
      this.closeModalSelecionaCota()
      this.coletarClickTabela(this.col, item)

    },
    closeModalSelecionaCota() {
      this.modalSelecionaCota = false
    },
    async openModalSelecionaCota(_data) {
      try {
        this.col = _data
        const data_splited = _data.split(" ")[1].split("/")
        const data = `${`${data_splited[2]}`.padStart(4, 0)}-${`${data_splited[1]
          }`.padStart(2, 0)}-${`${data_splited[0]}`.padStart(2, 0)}`
        const filtros = this.extractFiltersFromParams(null)
        this.carregandoDados = true
        const get = async () => {
          try {
            this.programacoesPai = await this.getProgramacoesFornecedorSimple({
              ...filtros,
              origem: this.empresaAtual,
              data_inicio: data,
              data_fim: data,
            })
            this.modalSelecionaCota = true
          } catch (error) {
            if (error.response) {
              this.errorMessage(error.response.data)
              return
            }
            if (error.message) {
              this.errorMessage(error.message)
              return
            }
            this.errorMessage(error)
          }
        }
        await get()
        this.carregandoDados = false
      } catch (error) {
        console.log(error)
      }
    },
    coletarClickTabela(col, item) {
      if (
        typeof item === 'object' &&
        item['PUBLIC_ID'] &&
        item['PUBLIC_ID'] !== ''
      ) {
        this.paramsModalEdicaoProgramacao.formTitle = 'Editar programação'
        this.paramsModalEdicaoProgramacao.dataSelecionada = col
        this.paramsModalEdicaoProgramacao.public_id = item['F_PUBLIC_ID']
        this.abrirFormProgramacaoRegiao = true
        return
      }
      if (
        typeof item === 'object' &&
        item.public_id &&
        item.public_id !== '') {
        this.paramsModalEdicaoProgramacao.formTitle = 'Editar programação'
        this.paramsModalEdicaoProgramacao.dataSelecionada = col
        this.paramsModalEdicaoProgramacao.public_id = item.public_id
        this.abrirFormProgramacaoRegiao = true
        return
      }
      if (col) {
        this.openModalSelecionaCota(col)
        return
      }
    },
  },
}
</script>

<style>
.cotas-header {
  margin-top: 5%;
}

.cotas-header-text {
  margin-left: 10%;
}

.cotas-detalhes {
  margin-top: 5%;
  margin-left: 10%;
}

.detail td {
  vertical-align: top;
}

.designados {
  color: #ff5332;
}

.distribuídos {
  color: #042b85;
}

.ocupado {
  color: #3233ff;
}

.saldo {
  color: #33ff32;
}
</style>
