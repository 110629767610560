const customChange = {
  data() {
    return {
      calling: false,
      last_search: '',
      c_last_event: '',
      c_timer: 0,
      c_typed: false,
      c_ms: 1000,
    }
  },
  methods: {
    /**
     * Essa função escuta o valor digitado e quando for alterado chama a callback `func`.
     * Respeita a assincronicidade. Isso é, após chamar a callback, não chamará denovo enquanto a mesma não terminar, mesmo que tenha alterações.
     * Salvo uma excessão, que, enquanto a callback é executada, é salvo as alterações no valor.
     * Ao término da callback, se existir alterações, a propria função(`customChange`) será chamada novamente.
     * Assim a callback `func` terá uma nova chamada para considerar o valor atual.
     *
     * Então se sua callback leva X segundos para ser executada.
     * Durante esses X segundos você pode digitar quantas vezes quiser.
     * Apenas no fim da excecução da callback que ela será chamada novamente passando o ultimo evento.
     * Gerando apenas 2 chamadas.
     *
     * Use junto ao evento `keyup`.
     * Essa funcão é uma alternativa ao uso do evento `change`.
     * O qual em campos \<v-autocomplete /\> não considera o valor digitado e sim o valor selecionado.
     *
     * @example
     *   ->@keyup="customChange($event, myfunc)"
     *   ->@keyup="customChange($event, myfunc, 2000)" => Espera 2 segundos antes continuar.
     *
     * @param {KeyboardEvent} event     Fixo, use sempre: $event
     * @param {Function} func    Callback
     * @param {Number} [ms]    Delay para esperar temrinar de digitar (ms)
     */
    async customChange(event, func, ms) {
      if (ms) {
        this.setMs(ms)
      }
      // Espera terminar de digitar
      if (this.c_ms && !this.c_typed) {
        clearTimeout(this.c_timer)
        new Promise((r) => {
          this.c_timer = setTimeout(async () => {
            this.c_typed = true
            await this.customChange(event, func)
            this.c_typed = false
            r()
          }, this.c_ms)
        })
        return
      }
      const value = event.target.value

      if (value.trim() == '') {
        return
      }

      if (this.calling) {
        this.c_last_event = event
        return
      }
      if (value == this.last_search) {
        return
      }
      try {
        this.calling = true
        this.last_search = value
        if (typeof func == 'function') {
          await func(event)
        } else {
          console.warn(`Expect \`func\` as a function!\nGot ${typeof func}`)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.calling = false
        if (this.c_last_event) {
          const last_event = this.c_last_event
          this.c_last_event = null
          this.customChange(last_event, func)
        }
      }
    },
    setMs(t = 500) {
      this.c_ms = t
    },
  },
}
export { customChange }
