<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <!-- <v-row>
      <v-col cols="12" sm="12">
        <filtro-programacao-unidade
          @showFilters="showFilters = !showFilters"
          @search="pesquisar"
        />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <v-row class="grey lighten-4" v-if="canList">
            <v-col v-if="showFilters" cols="2" sm="2">
              <form-filtro-programacao-regiao @pesquisar="pesquisar" />
            </v-col>
            <v-col
              :cols="showFilters ? 10 : 12"
              :sm="showFilters ? 10 : 12"
              class="grey lighten-5"
            >
              <calendario-programacao-regiao ref="calendario" />
            </v-col>
          </v-row>
          <v-row v-if="!canList">
            <v-col cols="12" sm="12" align="center">
              <span class="red--text"
                >Você não tem permissão para visualizar as informações desta
                tela</span
              >
            </v-col>
          </v-row>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CalendarioProgramacaoRegiao from '@/components/patio/Programacao/NovaRegiao/CalendarioProgramacaoRegiao.vue'
import FormFiltroProgramacaoRegiao from '@/components/patio/Programacao/NovaRegiao/Filtro/FormFiltroProgramacaoRegiao.vue'

import { mapMutations, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  data() {
    return {
      showFilters: true,
      editarTransportadora: false,
      relatorio: false,
      page: {
        title: 'Gerenciamento de programação por Região',
      },
      breadcrumbs: [
        {
          text: 'Programação por Região',
          disabled: true,
        },
      ],
      loading: false,
    }
  },
  components: {
    // FiltroProgramacaoUnidade,
    FormFiltroProgramacaoRegiao,
    CalendarioProgramacaoRegiao,
  },
  computed: {
    ...mapState('programacao', ['params']),

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        Sequences.CotasUnidade.toString()
      )
    },
  },
  methods: {
    ...mapMutations('programacao', ['SET_PARAMS']),
    async pesquisar(params) {
      if (params) {
        this.SET_PARAMS(params)
      }
      await this.$refs.calendario.atualizarResumoProgramacaoRegiao(params)
    },
  },
}
</script>

<style></style>
