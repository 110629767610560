var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"close-on-content-click":false,"offset-x":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.params.formTitle)+" ---")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"readonly":"","label":"Gate/Unidade","items":[
  _vm.programacaoFornecedor.programacao_unidade.proprietario ],"item-text":function (item) { return item.business_name +
' - ' +
"" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":"","prepend-icon":"mdi-boom-gate"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.proprietario
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "proprietario", $$v)},expression:"programacaoFornecedor.programacao_unidade.proprietario\n                  "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"readonly":"","label":"Fornecedor","items":[
  _vm.programacaoFornecedor.programacao_unidade.fornecedor ],"item-text":function (item) { return item.business_name +
' - ' +
"" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":"","prepend-icon":"mdi-equalizer"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.fornecedor
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "fornecedor", $$v)},expression:"programacaoFornecedor.programacao_unidade.fornecedor\n                  "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"readonly":"","label":"Tipo de Operação","items":_vm.programacaoFornecedor.programacao_unidade.tipo_controle
  ? [
    _vm.programacaoFornecedor.programacao_unidade
      .tipo_controle ]
  : [],"item-text":"descricao","item-value":"id","return-object":"","prepend-icon":"mdi-clipboard-flow-outline"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.tipo_operacao
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "tipo_operacao", $$v)},expression:"programacaoFornecedor.programacao_unidade.tipo_operacao\n                  "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"readonly":"","label":"Terminal","items":[
                    _vm.programacaoFornecedor.programacao_unidade.terminal ],"item-text":function (item) { return item.business_name +
' - ' +
"" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":"","prepend-icon":"mdi-alpha-t-box-outline"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.terminal),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "terminal", $$v)},expression:"programacaoFornecedor.programacao_unidade.terminal"}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{staticClass:"mt-3",attrs:{"readonly":"","label":"Tipo de controle","items":function () {
  _vm.i =
    _vm.programacaoFornecedor.programacao_unidade
      .tipo_controle
  return _vm.i ? [_vm.i] : []
},"item-text":_vm.descricao,"prepend-icon":"mdi-clipboard-alert-outline","return-object":""},model:{value:(_vm.programacaoFornecedor.programacao_unidade.tipo_controle
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "tipo_controle", $$v)},expression:"programacaoFornecedor.programacao_unidade.tipo_controle\n                  "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"readonly":"","label":"Tipos de Veículo","items":_vm.programacaoFornecedor.programacao_unidade.modalidades,"item-text":"descricao","item-value":"id","multiple":"","chips":"","prepend-icon":"mdi-truck-outline","return-object":""},model:{value:(_vm.programacaoFornecedor.programacao_unidade.modalidades
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "modalidades", $$v)},expression:"programacaoFornecedor.programacao_unidade.modalidades\n                  "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"readonly":"","label":"Tipos de Carrocerias","items":_vm.programacaoFornecedor.programacao_unidade.carrocerias,"item-text":"descricao","item-value":"id","multiple":"","chips":"","prepend-icon":"mdi-truck-outline","return-object":""},model:{value:(_vm.programacaoFornecedor.programacao_unidade.carrocerias
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "carrocerias", $$v)},expression:"programacaoFornecedor.programacao_unidade.carrocerias\n                  "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.programacaoFornecedor.programacao_unidade.observacao,"label":"Observação","prepend-icon":"mdi-note-text-outline"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.observacao
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "observacao", $$v)},expression:"programacaoFornecedor.programacao_unidade.observacao\n                  "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-autocomplete',{staticClass:"mt-3",attrs:{"readonly":"","label":"Produto","items":_vm.programacaoFornecedor.programacao_unidade.produto
                    ? [_vm.programacaoFornecedor.programacao_unidade.produto]
                    : [],"item-text":"descricao","item-value":"id","return-object":"","prepend-icon":"mdi-alpha-p-box-outline"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.produto),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "produto", $$v)},expression:"programacaoFornecedor.programacao_unidade.produto"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-autocomplete',{staticClass:"mt-3",attrs:{"readonly":"","items":_vm.programacaoFornecedor.programacao_unidade.sub_produto
  ? [
    _vm.programacaoFornecedor.programacao_unidade
      .sub_produto ]
  : [],"item-text":"descricao","label":"Sub Produto","item-value":"id","return-object":"","prepend-icon":"mdi-alpha-p-circle-outline"},model:{value:(_vm.programacaoFornecedor.programacao_unidade.sub_produto
                  ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacao_unidade, "sub_produto", $$v)},expression:"programacaoFornecedor.programacao_unidade.sub_produto\n                  "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-autocomplete',{staticClass:"mt-3",attrs:{"readonly":"","label":"Região","items":[_vm.programacaoFornecedor.regiao],"item-text":"sigla","item-value":"public_id","prepend-icon":"mdi-home-city","return-object":""},model:{value:(_vm.programacaoFornecedor.regiao),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor, "regiao", $$v)},expression:"programacaoFornecedor.regiao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-autocomplete',{attrs:{"readonly":"","items":_vm.programacaoFornecedor.tipos_frete,"item-text":"descricao","item-value":"id","label":"Tipo de Frete","prepend-icon":"mdi-truck","chips":"","multiple":"","return-object":""},model:{value:(_vm.programacaoFornecedor.tipos_frete),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor, "tipos_frete", $$v)},expression:"programacaoFornecedor.tipos_frete"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"mt-5 d-flex justify-space-around"},[_c('label',[_vm._v("Data inicial:")]),_c('input',{attrs:{"type":"datetime-local","readonly":"","name":"datetime"},domProps:{"value":_vm.date2iso(
                    new Date(
                      _vm.programacaoFornecedor.programacao_unidade.data_inicio
                    )
                  )}})])]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"mt-5 d-flex justify-space-around"},[_c('label',[_vm._v("Data final:")]),_c('input',{attrs:{"type":"datetime-local","readonly":"","name":"datetime"},domProps:{"value":_vm.date2iso(
                    new Date(
                      _vm.programacaoFornecedor.programacao_unidade.data_fim
                    )
                  )}})])])],1),_c('v-divider'),_c('v-divider'),_c('v-divider'),_c('v-divider'),_c('v-container',[(_vm.canCreate)?_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Distribuir nova cota")])]):_vm._e(),(_vm.canCreate)?_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('v-autocomplete',{staticClass:"mt-3",attrs:{"label":"Cliente","items":_vm.theClientes,"item-text":function (item) { return item.business_name +
                          ' - ' +
                          "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","hint":"Selecione o cliente.","persistent-hint":"","prepend-icon":"mdi-account","required":"","return-object":""},on:{"keyup":function($event){return _vm.customChange($event, _vm.handleGetClientes)}},model:{value:(_vm.editedItem.cliente),callback:function ($$v) {_vm.$set(_vm.editedItem, "cliente", $$v)},expression:"editedItem.cliente"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.pedidos.results.filter(
                        function (pedido) { return pedido.cliente.public_id ==
                          (_vm.editedItem.cliente
                            ? _vm.editedItem.cliente.public_id
                            : ''); }
                      ),"item-text":"numero_pedido","item-value":"id","label":"Pedidos","prepend-icon":"mdi-ticket-confirmation-outline","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os pedidos.","persistent-hint":"","required":"","return-object":""},model:{value:(_vm.editedItem.pedidos),callback:function ($$v) {_vm.$set(_vm.editedItem, "pedidos", $$v)},expression:"editedItem.pedidos"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"type":"number","label":"Toneladas","max":_vm.qt_peso(_vm.programacaoFornecedor),"min":0,"disabled":_vm.qt_peso(_vm.programacaoFornecedor) == 0,"prepend-icon":"mdi-weight","hint":"Indique quantas Toneladas será programada.","persistent-hint":"","rules":[
                          function () { return _vm.editedItem.qt_peso <=
                            _vm.qt_peso(_vm.programacaoFornecedor) ||
                            'Quantidade de toneladas é maior que a quantidade permitida.'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Restante: "+_vm._s(_vm.qt_peso(_vm.programacaoFornecedor))+" ")]},proxy:true}],null,true),model:{value:(_vm.editedItem.qt_peso),callback:function ($$v) {_vm.$set(_vm.editedItem, "qt_peso", $$v)},expression:"editedItem.qt_peso"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"type":"number","label":"Caminhões","max":_vm.qt_caminhoes(_vm.programacaoFornecedor),"min":0,"disabled":_vm.qt_caminhoes(_vm.programacaoFornecedor) == 0,"prepend-icon":"mdi-truck-check-outline","hint":"Indique quantos Caminhões será programado.","persistent-hint":"","rules":[
                          function () { return _vm.editedItem.qt_caminhoes <=
                            _vm.qt_caminhoes(_vm.programacaoFornecedor) ||
                            'Quantidade de caminhões é maior que a quantidade permitida.'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Restante: "+_vm._s(_vm.quantidade_restante_caminhoes_a_distribuir( _vm.programacaoFornecedor ))+" ")]},proxy:true}],null,true),model:{value:(_vm.editedItem.qt_caminhoes),callback:function ($$v) {_vm.$set(_vm.editedItem, "qt_caminhoes", $$v)},expression:"editedItem.qt_caminhoes"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"block":"","color":"info","disabled":(_vm.qt_peso(_vm.programacaoFornecedor) <= 0 &&
                        _vm.qt_caminhoes(_vm.programacaoFornecedor) <= 0) ||
                      _vm.editedItem.qt_peso > _vm.qt_peso(_vm.programacaoFornecedor) ||
                      _vm.editedItem.qt_caminhoes >
                      _vm.qt_caminhoes(_vm.programacaoFornecedor)},on:{"click":_vm.addProgramacao}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1):_vm._e(),_c('br'),(_vm.programacaoFornecedor.programacoes_regiao.length > 0)?_c('div',[_c('v-divider'),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Editar distribuição de cotas")])]),_vm._l((_vm.programacaoFornecedor.programacoes_regiao),function(programacao,i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('validation-provider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-3",attrs:{"label":"Cliente","items":_vm.theClientes,"error-messages":errors,"item-text":function (item) { return item.business_name +
  ' - ' +
  "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","hint":"Selecione o cliente.","persistent-hint":"","prepend-icon":"mdi-account","required":"","return-object":""},on:{"keyup":function($event){return _vm.customChange($event, _vm.handleGetClientes)}},model:{value:(_vm.programacaoFornecedor.programacoes_regiao[i]
                            .cliente
                            ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacoes_regiao[i]
                            , "cliente", $$v)},expression:"programacaoFornecedor.programacoes_regiao[i]\n                            .cliente\n                            "}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('validation-provider',{attrs:{"name":"pedidos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.pedidos.results.filter(
  function (pedido) { return pedido.cliente.public_id ==
    (_vm.programacaoFornecedor.programacoes_regiao[
      i
    ].cliente
      ? _vm.programacaoFornecedor
        .programacoes_regiao[i].cliente
        .public_id
      : ''); }
),"item-text":"numero_pedido","item-value":"public_id","error-messages":errors,"label":"Pedidos","prepend-icon":"mdi-ticket-confirmation-outline","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os pedidos.","persistent-hint":"","return-object":""},model:{value:(_vm.programacaoFornecedor.programacoes_regiao[i]
                            .pedidos
                            ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacoes_regiao[i]
                            , "pedidos", $$v)},expression:"programacaoFornecedor.programacoes_regiao[i]\n                            .pedidos\n                            "}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('validation-provider',{attrs:{"name":"qt_peso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"type":"number","label":"Toneladas","max":_vm.programacaoFornecedor.programacoes_regiao[i]
  .qt_peso *
  1 +
  _vm.qt_peso(_vm.programacaoFornecedor),"min":0,"disabled":_vm.programacaoFornecedor.qt_peso == 0,"prepend-icon":"mdi-weight","error-messages":errors,"hint":"Indique quantas Toneladas será programada.","persistent-hint":""},model:{value:(_vm.programacaoFornecedor.programacoes_regiao[i]
                            .qt_peso
                            ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacoes_regiao[i]
                            , "qt_peso", $$v)},expression:"programacaoFornecedor.programacoes_regiao[i]\n                            .qt_peso\n                            "}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('validation-provider',{attrs:{"name":"qt_caminhoes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"type":"number","label":"Caminhões","max":_vm.programacaoFornecedor.programacoes_regiao[i]
  .qt_caminhoes *
  1 +
  _vm.qt_caminhoes(_vm.programacaoFornecedor),"min":0,"disabled":_vm.programacaoFornecedor.qt_caminhoes == 0,"prepend-icon":"mdi-truck-check-outline","error-messages":errors,"hint":"Indique quantos Caminhões será programado.","persistent-hint":""},model:{value:(_vm.programacaoFornecedor.programacoes_regiao[i]
                            .qt_caminhoes
                            ),callback:function ($$v) {_vm.$set(_vm.programacaoFornecedor.programacoes_regiao[i]
                            , "qt_caminhoes", $$v)},expression:"programacaoFornecedor.programacoes_regiao[i]\n                            .qt_caminhoes\n                            "}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"block":"","color":"error"},on:{"click":function($event){return _vm.removeProgramacao(i)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)})],2):_vm._e(),(_vm.canCreate)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('label',[_vm._v("Replicar:")]),_c('br'),_c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(_vm.replicar),callback:function ($$v) {_vm.replicar=$$v},expression:"replicar"}},[_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.PRIMEIRO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.PRIMEIRO, _vm.programacaoFornecedor))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.SEGUNDO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.SEGUNDO, _vm.programacaoFornecedor))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.TERCEIRO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.TERCEIRO, _vm.programacaoFornecedor))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.QUARTO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.QUARTO, _vm.programacaoFornecedor))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.QUINTO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.QUINTO, _vm.programacaoFornecedor))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.SEXTO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.SEXTO, _vm.programacaoFornecedor))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.replicavel(_vm.DAYS.SETIMO)}},[_vm._v(" "+_vm._s(_vm.texto(_vm.DAYS.SETIMO, _vm.programacaoFornecedor))+" ")])],1)],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('div',[(!(
                    _vm.qt_peso(_vm.programacaoFornecedor) == 0 &&
                    _vm.qt_caminhoes(_vm.programacaoFornecedor) == 0
                  ) &&
                    _vm.editavel &&
                    _vm.notSuspended &&
                    !invalid
                    )?_c('router-link',{attrs:{"to":{
  name: 'NovoAgendamento',
  params: Object.assign({}, _vm.paramsToAgendamento(_vm.programacaoFornecedor)),
}}},[_c('v-btn',{attrs:{"color":"primary","small":""}},[_vm._v("Novo Agendamento")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-3",attrs:{"small":""},on:{"click":function($event){return _vm.detalhesDistribuicao(_vm.programacaoFornecedor)}}},[_vm._v(" Agendamentos ")])],1),_c('v-btn',{attrs:{"small":"","disabled":invalid || !_vm.editavel || !_vm.notSuspended},on:{"click":_vm.saveAll}},[_vm._v(" Salvar ")])],1)],1)],1)],1)]}}])})],1),_c('v-overlay',{attrs:{"value":_vm.carregaDetalhes}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1),_c('v-dialog',{model:{value:(_vm.detalhar),callback:function ($$v) {_vm.detalhar=$$v},expression:"detalhar"}},[_c('detalhes-programacao',{attrs:{"detalhes":_vm.detalhes,"headers":_vm.headersDetalhes},on:{"close":function () { return (_vm.detalhar = false); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }