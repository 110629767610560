<template>
  <div>
    <v-dialog v-model="show" :close-on-content-click="false" offset-x>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form>
            <v-card-title>
              <span class="headline">{{ params.formTitle }} ---</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete readonly v-model="programacaoFornecedor.programacao_unidade.proprietario
                    " label="Gate/Unidade" :items="[
    programacaoFornecedor.programacao_unidade.proprietario,
  ]" :item-text="(item) =>
  item.business_name +
  ' - ' +
  `${item.cnpj != null ? item.cnpj : item.cpf}`
  " item-value="public_id" return-object prepend-icon="mdi-boom-gate"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete readonly v-model="programacaoFornecedor.programacao_unidade.fornecedor
                    " label="Fornecedor" :items="[
    programacaoFornecedor.programacao_unidade.fornecedor,
  ]" :item-text="(item) =>
  item.business_name +
  ' - ' +
  `${item.cnpj != null ? item.cnpj : item.cpf}`
  " item-value="public_id" return-object prepend-icon="mdi-equalizer"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete readonly v-model="programacaoFornecedor.programacao_unidade.tipo_operacao
                    " label="Tipo de Operação" :items="programacaoFornecedor.programacao_unidade.tipo_controle
    ? [
      programacaoFornecedor.programacao_unidade
        .tipo_controle,
    ]
    : []
    " item-text="descricao" item-value="id" return-object prepend-icon="mdi-clipboard-flow-outline" />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete readonly v-model="programacaoFornecedor.programacao_unidade.terminal" label="Terminal"
                    :items="[
                      programacaoFornecedor.programacao_unidade.terminal,
                    ]" :item-text="(item) =>
  item.business_name +
  ' - ' +
  `${item.cnpj != null ? item.cnpj : item.cpf}`
  " item-value="public_id" return-object prepend-icon="mdi-alpha-t-box-outline"></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider class="my-5"></v-divider>
              <v-row>
                <v-col class="pt-0" cols="12" sm="4">
                  <v-autocomplete readonly class="mt-3" v-model="programacaoFornecedor.programacao_unidade.tipo_controle
                    " label="Tipo de controle" :items="() => {
    i =
      programacaoFornecedor.programacao_unidade
        .tipo_controle
    return i ? [i] : []
  }
    " :item-text="descricao" prepend-icon="mdi-clipboard-alert-outline" return-object></v-autocomplete>
                </v-col>
                <v-col class="pt-0" cols="12" sm="4">
                  <v-autocomplete readonly v-model="programacaoFornecedor.programacao_unidade.modalidades
                    " label="Tipos de Veículo" :items="programacaoFornecedor.programacao_unidade.modalidades
    " item-text="descricao" item-value="id" multiple chips prepend-icon="mdi-truck-outline"
                    return-object></v-autocomplete>
                </v-col>
                <v-col class="pt-0" cols="12" sm="4">
                  <v-autocomplete readonly v-model="programacaoFornecedor.programacao_unidade.carrocerias
                    " label="Tipos de Carrocerias" :items="programacaoFornecedor.programacao_unidade.carrocerias
    " item-text="descricao" item-value="id" multiple chips prepend-icon="mdi-truck-outline"
                    return-object></v-autocomplete>
                </v-col>
                <v-col class="pt-0" cols="12" sm="12">
                  <v-text-field readonly v-model="programacaoFornecedor.programacao_unidade.observacao
                    " :value="programacaoFornecedor.programacao_unidade.observacao
    " label="Observação" prepend-icon="mdi-note-text-outline" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <v-autocomplete readonly class="mt-3" v-model="programacaoFornecedor.programacao_unidade.produto"
                    label="Produto" :items="programacaoFornecedor.programacao_unidade.produto
                      ? [programacaoFornecedor.programacao_unidade.produto]
                      : []
                      " item-text="descricao" item-value="id" return-object
                    prepend-icon="mdi-alpha-p-box-outline"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-autocomplete readonly class="mt-3" v-model="programacaoFornecedor.programacao_unidade.sub_produto
                    " :items="programacaoFornecedor.programacao_unidade.sub_produto
    ? [
      programacaoFornecedor.programacao_unidade
        .sub_produto,
    ]
    : []
    " item-text="descricao" label="Sub Produto" item-value="id" return-object
                    prepend-icon="mdi-alpha-p-circle-outline"></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-autocomplete readonly class="mt-3" v-model="programacaoFornecedor.regiao" label="Região"
                    :items="[programacaoFornecedor.regiao]" item-text="sigla" item-value="public_id"
                    prepend-icon="mdi-home-city" return-object></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-autocomplete readonly v-model="programacaoFornecedor.tipos_frete"
                    :items="programacaoFornecedor.tipos_frete" item-text="descricao" item-value="id" label="Tipo de Frete"
                    prepend-icon="mdi-truck" chips multiple return-object />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <div class="mt-5 d-flex justify-space-around">
                    <label>Data inicial:</label>
                    <input type="datetime-local" readonly name="datetime" :value="date2iso(
                      new Date(
                        programacaoFornecedor.programacao_unidade.data_inicio
                      )
                    )
                      " />
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="mt-5 d-flex justify-space-around">
                    <label>Data final:</label>
                    <input type="datetime-local" readonly name="datetime" :value="date2iso(
                      new Date(
                        programacaoFornecedor.programacao_unidade.data_fim
                      )
                    )
                      " />
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-divider></v-divider>

              <v-container>
                <v-card-title v-if="canCreate">
                  <span class="headline">Distribuir nova cota</span>
                </v-card-title>
                <v-row v-if="canCreate">
                  <v-col cols="11">
                    <v-row>
                      <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                        <v-autocomplete class="mt-3" v-model="editedItem.cliente" label="Cliente" :items="theClientes"
                          :item-text="(item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                            " item-value="public_id" hint="Selecione o cliente." persistent-hint
                          prepend-icon="mdi-account" required return-object
                          @keyup="customChange($event, handleGetClientes)"></v-autocomplete>
                      </v-col>
                      <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                        <v-autocomplete v-model="editedItem.pedidos" :items="pedidos.results.filter(
                          (pedido) =>
                            pedido.cliente.public_id ==
                            (editedItem.cliente
                              ? editedItem.cliente.public_id
                              : '')
                        )
                          " item-text="numero_pedido" item-value="id" label="Pedidos"
                          prepend-icon="mdi-ticket-confirmation-outline" chips clearable deletable-chips multiple
                          hint="Selecione os pedidos." persistent-hint required return-object></v-autocomplete>
                      </v-col>
                      <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                        <v-text-field class="mt-3" type="number" v-model="editedItem.qt_peso" label="Toneladas"
                          :max="qt_peso(programacaoFornecedor)" :min="0" :disabled="qt_peso(programacaoFornecedor) == 0"
                          prepend-icon="mdi-weight" hint="Indique quantas Toneladas será programada." persistent-hint
                          :rules="[
                            () =>
                              editedItem.qt_peso <=
                              qt_peso(programacaoFornecedor) ||
                              'Quantidade de toneladas é maior que a quantidade permitida.',
                          ]">
                          <template v-slot:label>
                            Restante: {{ qt_peso(programacaoFornecedor) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                        <v-text-field class="mt-3" type="number" v-model="editedItem.qt_caminhoes" label="Caminhões"
                          :max="qt_caminhoes(programacaoFornecedor)" :min="0"
                          :disabled="qt_caminhoes(programacaoFornecedor) == 0" prepend-icon="mdi-truck-check-outline"
                          hint="Indique quantos Caminhões será programado." persistent-hint :rules="[
                            () =>
                              editedItem.qt_caminhoes <=
                              qt_caminhoes(programacaoFornecedor) ||
                              'Quantidade de caminhões é maior que a quantidade permitida.',
                          ]">
                          <template v-slot:label>
                            Restante:
                            {{
                              quantidade_restante_caminhoes_a_distribuir(
                                programacaoFornecedor
                              )
                            }}
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-btn block color="info" class="text-capitalize" @click="addProgramacao" :disabled="(qt_peso(programacaoFornecedor) <= 0 &&
                          qt_caminhoes(programacaoFornecedor) <= 0) ||
                        editedItem.qt_peso > qt_peso(programacaoFornecedor) ||
                        editedItem.qt_caminhoes >
                        qt_caminhoes(programacaoFornecedor)
                        "><v-icon>mdi-plus</v-icon></v-btn>
                  </v-col>
                </v-row>
                <br />
                <div v-if="programacaoFornecedor.programacoes_regiao.length > 0">
                  <v-divider></v-divider>
                  <v-card-title>
                    <span class="headline">Editar distribuição de cotas</span>
                  </v-card-title>
                  <v-row v-for="(
                      programacao, i
                    ) in programacaoFornecedor.programacoes_regiao" v-bind:key="i">
                    <v-col cols="11">
                      <v-row>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <validation-provider v-slot="{ errors }" name="cliente" rules="required">
                            <v-autocomplete class="mt-3" v-model="programacaoFornecedor.programacoes_regiao[i]
                              .cliente
                              " label="Cliente" :items="theClientes" :error-messages="errors" :item-text="(item) =>
    item.business_name +
    ' - ' +
    `${item.cnpj != null ? item.cnpj : item.cpf}`
    " item-value="public_id" hint="Selecione o cliente." persistent-hint prepend-icon="mdi-account" required
                              return-object @keyup="customChange($event, handleGetClientes)"></v-autocomplete>
                          </validation-provider>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <validation-provider v-slot="{ errors }" name="pedidos">
                            <v-autocomplete v-model="programacaoFornecedor.programacoes_regiao[i]
                              .pedidos
                              " :items="pedidos.results.filter(
    (pedido) =>
      pedido.cliente.public_id ==
      (programacaoFornecedor.programacoes_regiao[
        i
      ].cliente
        ? programacaoFornecedor
          .programacoes_regiao[i].cliente
          .public_id
        : '')
  )
    " item-text="numero_pedido" item-value="public_id" :error-messages="errors" label="Pedidos"
                              prepend-icon="mdi-ticket-confirmation-outline" chips clearable deletable-chips multiple
                              hint="Selecione os pedidos." persistent-hint return-object></v-autocomplete>
                          </validation-provider>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <validation-provider v-slot="{ errors }" name="qt_peso" rules="required">
                            <v-text-field class="mt-3" type="number" v-model="programacaoFornecedor.programacoes_regiao[i]
                              .qt_peso
                              " label="Toneladas" :max="programacaoFornecedor.programacoes_regiao[i]
    .qt_peso *
    1 +
    qt_peso(programacaoFornecedor)
    " :min="0" :disabled="programacaoFornecedor.qt_peso == 0" prepend-icon="mdi-weight" :error-messages="errors"
                              hint="Indique quantas Toneladas será programada." persistent-hint>
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <validation-provider v-slot="{ errors }" name="qt_caminhoes" rules="required">
                            <v-text-field class="mt-3" type="number" v-model="programacaoFornecedor.programacoes_regiao[i]
                              .qt_caminhoes
                              " label="Caminhões" :max="programacaoFornecedor.programacoes_regiao[i]
    .qt_caminhoes *
    1 +
    qt_caminhoes(programacaoFornecedor)
    " :min="0" :disabled="programacaoFornecedor.qt_caminhoes == 0
    " prepend-icon="mdi-truck-check-outline" :error-messages="errors" hint="Indique quantos Caminhões será programado."
                              persistent-hint>
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-btn block color="error" class="text-capitalize"
                        @click="removeProgramacao(i)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-row v-if="canCreate">
                  <v-col cols="12" md="12" lg="6">
                    <label>Replicar:</label>
                    <br />
                    <v-btn-toggle v-model="replicar" multiple>
                      <v-btn :disabled="!replicavel(DAYS.PRIMEIRO)">
                        {{ texto(DAYS.PRIMEIRO, programacaoFornecedor) }}
                      </v-btn>
                      <v-btn :disabled="!replicavel(DAYS.SEGUNDO)">
                        {{ texto(DAYS.SEGUNDO, programacaoFornecedor) }}
                      </v-btn>
                      <v-btn :disabled="!replicavel(DAYS.TERCEIRO)">
                        {{ texto(DAYS.TERCEIRO, programacaoFornecedor) }}
                      </v-btn>
                      <v-btn :disabled="!replicavel(DAYS.QUARTO)">
                        {{ texto(DAYS.QUARTO, programacaoFornecedor) }}
                      </v-btn>
                      <v-btn :disabled="!replicavel(DAYS.QUINTO)">
                        {{ texto(DAYS.QUINTO, programacaoFornecedor) }}
                      </v-btn>
                      <v-btn :disabled="!replicavel(DAYS.SEXTO)">
                        {{ texto(DAYS.SEXTO, programacaoFornecedor) }}
                      </v-btn>
                      <v-btn :disabled="!replicavel(DAYS.SETIMO)">
                        {{ texto(DAYS.SETIMO, programacaoFornecedor) }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <div>
                    <router-link v-if="!(
                      qt_peso(programacaoFornecedor) == 0 &&
                      qt_caminhoes(programacaoFornecedor) == 0
                    ) &&
                      editavel &&
                      notSuspended &&
                      !invalid
                      " :to="{
    name: 'NovoAgendamento',
    params: {
      ...paramsToAgendamento(programacaoFornecedor),
    },
  }">
                      <v-btn color="primary" small>Novo Agendamento</v-btn>
                    </router-link>
                    <v-btn small class="mx-3" @click="detalhesDistribuicao(programacaoFornecedor)">
                      Agendamentos
                    </v-btn>
                  </div>
                  <v-btn small :disabled="invalid || !editavel || !notSuspended" @click="saveAll">
                    Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
      <v-overlay :value="carregaDetalhes">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="detalhar">
      <detalhes-programacao :detalhes="detalhes" :headers="headersDetalhes" @close="() => (detalhar = false)" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { DAYS, TIME_DAYS, date2iso, WEEKDAYS } from '@/mixins/days.js'
import { paramsToAgendamento, contabilizadores } from '@/mixins/cotas.js'
import { parseDate } from '@/mixins/convertion.js'
import { customChange } from '@/mixins/autocomplete.js'

import { BasicPermissions, Sequences } from '@/utils/permissions'

// import SuspensaoProgramacaoUnidade from './SuspensaoProgramacaoUnidade.vue'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

import ClienteApi from '@/services/tenants/clientes.js'

export default {
  props: {
    params: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [contabilizadores, paramsToAgendamento, parseDate, customChange],
  components: { DetalhesProgramacao },
  async created() {
    this.DAYS = DAYS
    this.TIME_DAYS = TIME_DAYS
    this.WEEKDAYS = WEEKDAYS
    this.date2iso = date2iso
  },
  data() {
    return {
      detalhes: [],
      detalhar: false,
      headersDetalhes: [
        { text: 'Status', value: 'status' },
        { text: 'Placa', value: 'veiculo_placa' },
        { text: 'Motorista', value: 'motorista_name' },
        { text: 'Cliente', value: 'cliente_name' },
        { text: 'Região', value: 'regiao_sigla' },
        { text: 'Data do Agendamento', value: 'agendado_em' },
      ],
      clientes: [],
      editting: false,
      selectedEvent: {
        startTime: null,
        endTime: null,
      },
      defaultItem: {
        public_id: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        cliente: null,
        pedidos: [],
      },
      editedItem: {
        public_id: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        cliente: null,
        pedidos: [],
      },

      replicar: [],
      replicar_possibilidades: Array.from(7).fill(false),
      replicar_public_ids: Array.from(6).fill(null),
      carregaDetalhes: false,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['pedidos']),
    ...mapState('programacao', ['programacaoFornecedor']),

    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    permission() {
      return Sequences.CotasRegiao.toString()
    },

    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },

    notSuspended() {
      if (this.programacaoFornecedor) {
        return this.programacaoFornecedor.programacao_unidade.suspendido == 0
      }
      return true
    },
    editavel() {
      if (this.programacaoFornecedor) {
        if (
          new Date(this.programacaoFornecedor.programacao_unidade.data_fim) >
          new Date()
        ) {
          return true
        }
      }
      return false
    },
    theClientes() {
      let clientes_programacoes = this.programacaoFornecedor.programacoes_regiao.map(
        (prog) => prog.cliente
      )
      return []
        .concat(clientes_programacoes, this.clientes, this.editedItem.cliente)
        .filter((item) => item)
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.iniciarEdicao()
      }
    },
  },

  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('programacao', [
      'getProgramacaoFornecedor',
      'addProgramacaoRegiao',
      'editProgramacaoRegiao',
      'getProgramacaoDetalhe',
    ]),
    ...mapActions('patio', ['deleteProgramacaoRegiao']),
    ...mapMutations('patio', ['SET_PEDIDOS']),
    ...mapMutations('programacao', ['SET_PROGRAMACAO_FORNECEDOR']),

    iniciarEdicao() {
      Object.assign(this.editedItem, this.defaultItem)
      const [dia, mes, ano] = this.params.dataSelecionada
        .split(' ')[1]
        .split('/')
      this.selectedEvent.startTime = new Date(ano, mes - 1, dia, 0, 0)
      this.selectedEvent.endTime = new Date(ano, mes - 1, dia, 23, 59, 0)
      // this.editedItem.public_id = this.params.public_id || null
      this.editItem({ public_id: this.params.public_id })
    },

    replicavel(dia) {
      return this.replicar_possibilidades[dia]
    },

    async detalhesDistribuicao(item) {
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Fornecedor',
          public_id: item.public_id,
        })
        this.detalhes = data
        this.detalhar = true
      } catch (error) {
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },

    async handleGetClientes(event) {
      const value = event.target.value
      this.clientes = await ClienteApi.getClientes(
        this.empresaAtual.public_id,
        {
          pesquisa: value,
        }
      )
    },

    async atualizarListaPedidos() {
      this.SET_PEDIDOS({ results: [], count: 0 })
    },

    addProgramacao() {
      this.programacaoFornecedor.programacoes_regiao.push({
        ...this.editedItem,
      })
      Object.assign(this.editedItem, this.defaultItem)
    },

    async removeProgramacao(index) {
      console.log("Removing...")
      console.log(this.programacaoFornecedor.programacoes_regiao)
      console.log(index)
      let programacao = this.programacaoFornecedor.programacoes_regiao[index]
      if (programacao.public_id != '') {
        try {
          await this.deleteProgramacaoRegiao(programacao)
          this.programacaoFornecedor.programacoes_regiao.splice(index, 1)
        } catch (error) {
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            this.errorMessage(
              'Empresa atual não pode cadastrar/editar programações.'
            )
          } else {
            if (error.response) {
              this.errorMessage(error.response.data)
              return
            }
            if (error.message) {
              this.errorMessage(error.message)
              return
            }
            this.errorMessage(error)
          }
        }
      }
      this.programacaoFornecedor.programacoes_regiao.splice(index, 1)
    },

    async saveAll() {
      await Promise.all(
        this.programacaoFornecedor.programacoes_regiao.map(
          async (programacao) => {
            try {
              let prog_regiao = await this.save({
                ...programacao,
                programacao_fornecedor: this.programacaoFornecedor,
              })
              Object.assign(programacao, prog_regiao)
            } catch (error) {
              if (
                error.response &&
                (error.response.status == 403 || error.response.status == 404)
              ) {
                this.errorMessage(
                  'Empresa atual não pode cadastrar/editar programações.'
                )
              } else {
                if (error.response) {
                  this.errorMessage(error.response.data)
                  return
                }
                if (error.message) {
                  this.errorMessage(error.message)
                  return
                }
                this.errorMessage(error)
              }
            }
          }
        )
      )
    },

    async save(programacao) {
      try {
        let prog_regiao
        if (programacao.public_id != '') {
          prog_regiao = await this.editProgramacaoRegiao(programacao)
          if (this.replicar.length > 0) {
            await this.editarReplicas(
              prog_regiao,
              this.editProgramacaoRegiao,
              this.addProgramacaoRegiao
            )
          }
        } else {
          prog_regiao = await this.addProgramacaoRegiao(programacao)
          if (this.replicar.length > 0) {
            await this.cadastrarReplicas(prog_regiao, this.addProgramacaoRegiao)
          }
        }
        this.close()
        return prog_regiao
      } catch (error) {
        console.log(error)
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage(
            'Empresa atual não pode cadastrar/editar programações.'
          )
        } else {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },

    texto(ordem, programacao) {
      let dia = new Date(
        programacao.original
          ? programacao.original.data_inicio
          : programacao.data_inicio
      )
      let result = dia.setDate(dia.getDate() + ordem)

      return WEEKDAYS[new Date(result).getDay()]
    },
    async editItem(item) {
      if (item.public_id) {
        item = await this.getProgramacaoFornecedor(item.public_id)
        // programacaoFornecedor
      }
      this.replicar = []
      // Object.assign(this.editedItem, item)

      // this.editReplicas(item, this.programacoesFornecedor)
      this.editedItem.data_inicio = item.public_id
        ? this.date2iso(new Date(item.data_inicio))
        : this.date2iso(this.selectedEvent.startTime)
      this.editedItem.data_fim = item.public_id
        ? this.date2iso(new Date(item.data_fim))
        : this.date2iso(this.selectedEvent.endTime)

      await this.atualizarListaPedidos()
      this.SET_PROGRAMACAO_FORNECEDOR(item)
    },

    close() {
      this.editting = false
      this.show = false
      setTimeout(() => {
        this.selectedEvent.item = { ...this.editedItem }
        Object.assign(this.editedItem, this.defaultItem)
      }, 100)
    },
  },
}
</script>
