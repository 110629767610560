<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ title }}</span>
            <v-switch v-model="sem_cotas" class="ml-5" />
            <span class="text-subtitle-2">Exibir cotas sem quantidade restante?</span>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line
                hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="$emit('close')"> Fechar </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="programacoes.filter(
                        (item) =>
                            sem_cotas || (item.restante.qt_peso > 0 ||
                                item.restante.qt_caminhoes > 0)
                    )
                        " :search="search">
                        <template v-slot:[`item.data_inicio`]="{ item }">
                            {{ parseDatetime(new Date(item.data_inicio)) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
  
<script>
import { parseDatetime } from '@/mixins/convertion.js'
export default {
    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
        programacoes: {
            type: Array,
            required: true,
        },
    },
    mixins: [parseDatetime],
    data() {
        return {
            title: 'Selecionar Programação',
            headers: [
                { text: 'Ações', value: 'actions', sortable: false },
                { text: 'Data da cota', value: 'data_inicio' },
                { text: 'Peso restante', value: 'restante.qt_peso' },
                { text: 'Caminhões restante', value: 'restante.qt_caminhoes' },
                { text: 'Gate', value: 'proprietario' },
                {
                    text: 'Fornecedor',
                    value: 'fornecedor',
                },
                { text: 'Terminal', value: 'terminal' },
                { text: 'Tipo de Operação', value: 'tipo_operacao' },
                { text: 'Produto', value: 'produto' },
                { text: 'Subprodudo', value: 'sub_produto' },
                { text: 'Região', value: 'regiao' },
                { text: 'Tipo de frete', value: 'tipos_frete' },
                { text: 'Identificador', value: 'public_id' },
            ],
            search: '',
            sem_cotas: false,
        }
    },
    computed: {
    },
    methods: {
        async editItem(item) {
            this.$emit('selecionaCota', item)
        },
    },
}
</script>
  