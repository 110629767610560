<template>
  <div>
    <div class="d-flex flex-row">
      <template v-if="programacoes.headers.length > 0">
        <v-switch v-model="resumo" flat label="Visualizar Resumo"></v-switch>
        <v-switch v-model="detalhe" flat label="Visualizar Detalhes"></v-switch>
      </template>
    </div>
    <v-data-table
      :headers="programacoes.headers"
      :items="programacoes.dados"
      :items-per-page="-1"
      disable-sort
      dense
    >
      <template v-slot:item="{ item }">
        <tr
          class="detail"
          :class="itemClass(item)"
          v-if="viewRow(item['visivel'], item['info'])"
        >
          <td v-for="(col, i) in programacoes.headers" :key="i">
            <template v-if="col.value === 'info'">
              <div class="cotas-header" v-if="typeof item['info'] === 'object'">
                <table class="full-table">
                  <tr v-for="(value, key, i) in item['info']" :key="i">
                    <td>{{ key }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </table>
              </div>
              <div v-else>
                {{ item['info'] }}
              </div>
            </template>
            <template v-else>
              <div v-if="typeof item[col.value] === 'object'">
                <h5 class="text-center my-2">{{ col.value }}</h5>
                <div v-for="(prog, i) in item[col.value]" :key="i">
                  <div class="cotas-header">
                    <h4>
                      <v-icon
                        :color="
                          Object.keys(prog).length > 0
                            ? prog.SUSPENDIDO
                              ? 'red'
                              : 'green'
                            : 'black'
                        "
                        @click="$emit('detalhesDistribuicao', prog)"
                        >mdi-truck</v-icon
                      >
                      <span class="cotas-header-text">Detalhes</span>
                    </h4>
                  </div>
                  <div
                    class="cotas-detalhes"
                    @click="$emit('coletarClickTabela', col.value, prog)"
                  >
                    <table class="full-table font-8">
                      <tr v-for="(value, key, i) in prog" :key="i">
                        <td
                          v-if="
                            key !== 'PUBLIC_ID' &&
                            key !== 'SUSPENDIDO' &&
                            inMemoryView[key]
                          "
                        >
                          {{ key }}
                        </td>
                        <td
                          v-if="
                            key !== 'PUBLIC_ID' &&
                            key !== 'SUSPENDIDO' &&
                            inMemoryView[key]
                          "
                        >
                          {{ value }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div
                v-else-if="item['info'] !== ''"
                class="text-center"
                @click="$emit('coletarClickTabela', col.value, item)"
              >
                {{ item[col.value] || 0 }}
              </div>
            </template>
          </td>
        </tr>
        <tr class="empty-line" v-if="item.visivel === undefined"></tr>
        <tr class="empty-line" v-if="item.visivel === undefined"></tr>
      </template>
      <template #no-data>
        <span> Nenhuma informação obtida na pesquisa </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    programacoes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inMemoryView: {
        global: true,
      },
      resumo: false,
      detalhe: false,
    }
  },
  computed: {
    itemClass() {
      return (item) => {
        if (typeof item['info'] !== 'object') {
          return item['info'].toLowerCase()
        }
      }
    },
  },
  methods: {
    viewRow(visivel, field) {
      const res =
        visivel === undefined ||
        visivel == 'global' ||
        (visivel == 'resumo' && this.resumo) ||
        (visivel == 'detalhe' && this.detalhe)
      this.inMemoryView[field] = res
      return res
    },
  },
  watch: {
    detalhe(newValue) {
      this.resumo = newValue ? true : this.resumo
    },
    resumo(newValue) {
      this.detalhe = !newValue ? false : this.detalhe
    },
  },
}
</script>

<style scoped>
.empty-line {
  height: 8px;
  line-height: 8px;
}
.full-table {
  width: 100%;
  height: 100%;
}

.full-table tr:last-child td {
  border-bottom: 0;
}

.font-8 {
  font-size: 8px;
}

.cotas-header {
  margin-top: 8px;
  font-size: 10px;
  align-self: center;
}
.cotas-header-text {
  align-self: center;
  margin-left: 10%;
}
.cotas-detalhes {
  margin-top: 8px;
  font-size: 10px;
  align-self: center;
  /* margin-left: 10%; */
}

.cotas-resumo {
  text-align: center;
  font-size: 10px;
}

.detail:nth-child(odd) {
  background-color: #eff;
}

.detail td {
  vertical-align: top;
  font-size: 10px;
}
.designados {
  color: #ff5332;
}
.distribuídos {
  color: #042b85;
}
.ocupado {
  color: #3233ff;
  font-size: 10px;
}
.saldo {
  color: #339f33;
}

.v-application p {
  margin-bottom: 1px;
}
</style>
