const DAYS = Object.freeze({
    DOMINGO: 0,
    SEGUNDA: 1,
    TERCA: 2,
    QUARTA: 3,
    QUINTA: 4,
    SEXTA: 5,
    SABADO: 6,
    PRIMEIRO: 0,
    SEGUNDO: 1,
    TERCEIRO: 2,
    QUARTO: 3,
    QUINTO: 4,
    SEXTO: 5,
    SETIMO: 6,
})
const WEEKDAYS = Object.freeze({
    0: "DOM.",
    1: "SEG.",
    2: "TER.",
    3: "QUA.",
    4: "QUI.",
    5: "SEX.",
    6: "SAB.",
})
const getDateMinMax = (date) => {
    return {
        "min": new Date(`${`${date.getFullYear()}`.padStart(
            2,
            '0'
        )}-${`${date.getMonth() + 1}`.padStart(
            2,
            '0'
        )}-${`${date.getDate()}`.padStart(
            2,
            '0'
        )}T00:00:00`
        ),
        "max": new Date(`${`${date.getFullYear()}`.padStart(
            2,
            '0'
        )}-${`${date.getMonth() + 1}`.padStart(
            2,
            '0'
        )}-${`${date.getDate()}`.padStart(
            2,
            '0'
        )}T23:59:59`
        )
    }
}
const TIME_DAYS = (t) => { return t * 24 * 60 * 60 * 1000; };
const TIME_MINS = (t) => { return t * 60 * 1000; };
const date2iso = (date) => {
    if (!date) return null
    return `${`${date.getFullYear()}`.padStart(
        4,
        0
    )}-${`${date.getMonth() + 1}`.padStart(
        2,
        0
    )}-${`${date.getDate()}`.padStart(
        2,
        0
    )}T${`${date.getHours()}`.padStart(
        2,
        0
    )}:${`${date.getMinutes()}`.padStart(
        2,
        0
    )}:${`${date.getSeconds()}`.padStart(2, 0)}.${`${date.getMilliseconds()}`.padStart(3, 0)}`
}
export { DAYS, WEEKDAYS, TIME_DAYS, TIME_MINS, date2iso, getDateMinMax };

