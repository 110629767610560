import TipoAgendamento from '@/utils/agendamento'

const paramsToAgendamento = {
  methods: {
    paramsToAgendamento(item) {
      let programacao_unidade = null
      let programacao_fornecedor = null
      let programacao_regiao = null
      let programacao_cliente = null
      let params = {}
      if (item.programacao_regiao) {
        // Aqui a programação é por transportadora
        programacao_cliente = item
      } else {
        if (item.programacao_fornecedor) {
          // Aqui a programação é por cliente
          programacao_regiao = item
        } else {
          if (item.programacao_unidade) {
            // Aqui a programação é por região
            programacao_fornecedor = item
          } else {
            // Aqui a programação é por fornecedor
            programacao_unidade = item
          }
        }
      }
      if (programacao_cliente) {
        programacao_regiao = programacao_cliente.programacao_regiao
      }
      if (programacao_regiao) {
        programacao_fornecedor = programacao_regiao.programacao_fornecedor
      }
      if (programacao_fornecedor) {
        programacao_unidade = programacao_fornecedor.programacao_unidade
      }
      params.tipoAgendamento = TipoAgendamento.DaProgramacao
      params.programacaoUnidade = programacao_unidade
      params.programacaoFornecedor = programacao_fornecedor
      params.programacaoRegiao = programacao_regiao
      params.programacaoCliente = programacao_cliente
      params.data_cota = programacao_cliente
      return params
    },
  },
}

const contabilizadores = {
  methods: {
    get_cotas_filhos(item) {
      let cotas_filho
      // Define qual o atributo das cotas filha
      if (item.programacoes_fornecedor) {
        // Aqui, o item é uma cota do tipo programação por fornecedor
        cotas_filho = item.programacoes_fornecedor
      }
      if (item.programacoes_regiao) {
        // Aqui, o item é uma cota do tipo programação por região
        cotas_filho = item.programacoes_regiao
      }
      if (item.programacoes_cliente) {
        // Aqui, o item é uma cota do tipo programação por cliente
        cotas_filho = item.programacoes_cliente
      }
      return cotas_filho
    },
    quantidade_distribuida_peso(item) {
      let quantidade = 0,
        cotas_filho = this.get_cotas_filhos(item)
      if (cotas_filho) {
        if (cotas_filho.length == 0) {
          quantidade = 0
        }
        if (cotas_filho.length == 1) {
          quantidade = cotas_filho[0].qt_peso * 1
        }
        if (cotas_filho.length > 1) {
          quantidade = cotas_filho.reduce((accumulator, currentValue) => {
            return (
              (accumulator.qt_peso * 1 || accumulator * 1 || 0) * 1 +
              currentValue.qt_peso * 1
            )
          })
        }
      }
      return quantidade
    },
    quantidade_distribuida_caminhoes(item) {
      let quantidade = 0,
        cotas_filho = this.get_cotas_filhos(item)
      if (cotas_filho) {
        if (cotas_filho.length == 0) {
          quantidade = 0
        }
        if (cotas_filho.length == 1) {
          quantidade = cotas_filho[0].qt_caminhoes * 1
        }
        if (cotas_filho.length > 1) {
          quantidade = cotas_filho.reduce((accumulator, currentValue) => {
            return (
              (accumulator.qt_caminhoes * 1 || accumulator * 1 || 0) * 1 +
              currentValue.qt_caminhoes * 1
            )
          })
        }
      }
      return quantidade
    },
    quantidade_agendada_peso(item) {
      let quantidade = 0
      if (item.agendamentos) {
        for (var agendamento of item.agendamentos) {
          for (var pedido of agendamento.pedidos) {
            quantidade += pedido.quantidade
          }
        }
      } else {
        // 0 Se o agendamento for por caminhões ou não não tiver o atributo agendamentos
        quantidade = 0
      }
      return quantidade
    },
    quantidade_agendada_caminhoes(item) {
      let quantidade
      if (item.agendamentos && item.qt_caminhoes != 0) {
        quantidade = item.agendamentos.filter((p) => p.status == 'AGENDADO')
          .length
      } else {
        // 0 Se o agendamento for por peso ou não não tiver o atributo agendamentos
        quantidade = 0
      }
      return quantidade
    },
    quantidade_triagem_caminhoes(item) {
      let quantidade
      if (item.agendamentos && item.qt_caminhoes != 0) {
        quantidade = item.agendamentos.filter(
          (p) => p.status != 'AGENDADO' && p.status != 'CANCELADO'
        ).length
      } else {
        // 0 Se o agendamento for por peso ou não não tiver o atributo agendamentos
        quantidade = 0
      }
      return quantidade
    },

    peso_triagem_caminhoes(item) {
      let quantidade
      if (item.agendamentos) {
        quantidade = item.agendamentos.filter((p) => p.status != 'AGENDADO')
          .length
      } else {
        // 0 Se o agendamento for por peso ou não não tiver o atributo agendamentos
        quantidade = 0
      }
      return quantidade
    },
    quantidade_agendada_total_peso(item) {
      let quantidade = 0
      if (item) {
        if (item.agendamentos) {
          // To do
          quantidade = item.qt_peso
        } else {
          // 0 Se o agendamento for por caminhões ou não não tiver o atributo agendamentos
          quantidade = 0
        }
      }
      return quantidade
    },
    quantidade_agendada_total_caminhoes(item) {
      let quantidade = 0
      if (item) {
        let cotas_filhos = this.get_cotas_filhos(item)
        if (item.agendamentos && item.qt_caminhoes != 0) {
          quantidade =
            item.agendamentos.length +
            this.quantidade_agendada_total_caminhoes(cotas_filhos)
        } else {
          // 0 Se o agendamento for por peso ou não não tiver o atributo agendamentos
          quantidade = 0
        }
      }
      return quantidade
    },
    quantidade_triagem_total_caminhoes(item) {
      let quantidade = 0
      if (item) {
        let cotas_filhos = this.get_cotas_filhos(item)
        if (item.agendamentos && item.qt_caminhoes != 0) {
          quantidade =
            item.agendamentos.length +
            this.quantidade_agendada_total_caminhoes(cotas_filhos)
        } else {
          // 0 Se o agendamento for por peso ou não não tiver o atributo agendamentos
          quantidade = 0
        }
      }
      return quantidade
    },
    quantidade_distribuida(item, tipo) {
      let quantidade = 0
      if (tipo == 'peso') {
        quantidade = this.quantidade_distribuida_peso(item)
      }
      if (tipo == 'caminhoes') {
        quantidade = this.quantidade_distribuida_caminhoes(item)
      }
      return quantidade
    },
    quantidade_agendada(item, tipo) {
      let quantidade = 0
      if (item) {
        if (tipo == 'peso') {
          quantidade = this.quantidade_agendada_peso(item)
        }
        if (tipo == 'caminhoes') {
          quantidade = this.quantidade_agendada_caminhoes(item)
        }
      }
      return quantidade
    },
    quantidade_agendada_total(item, tipo) {
      let quantidade = 0
      if (item) {
        if (tipo == 'peso') {
          quantidade = this.quantidade_agendada_total_peso(item)
        }
        if (tipo == 'caminhoes') {
          quantidade = this.quantidade_agendada_total_caminhoes(item)
        }
      }
      return quantidade
    },
    quantidade_triagem_total(item, tipo) {
      let quantidade = 0
      if (item) {
        if (tipo == 'peso') {
          quantidade = this.quantidade_agendada_total_peso(item)
        }
        if (tipo == 'caminhoes') {
          quantidade = this.quantidade_agendada_total_caminhoes(item)
        }
      }
      return quantidade
    },
    quantidade_restante_peso(item) {
      let quantidade_restante, quantidade_agendada
      quantidade_restante = item.qt_peso
      quantidade_agendada = this.quantidade_agendada_peso(item, 'peso')
      return quantidade_restante - quantidade_agendada
    },
    quantidade_restante_caminhoes(item) {
      let quantidade_restante, quantidade_triada, quantidade_agendada
      quantidade_restante = item.qt_caminhoes
      let param = 'caminhoes'
      if (item.qt_peso > 0) {
        param = 'peso'
      }
      quantidade_agendada = this.quantidade_agendada(item, param)
      quantidade_triada = this.quantidade_triagem_caminhoes(item, param)

      return quantidade_restante - quantidade_agendada - quantidade_triada
    },
    quantidade_atendida(item) {
      let quantidade_triada, quantidade_agendada
      quantidade_agendada = this.quantidade_agendada(item, 'caminhoes')
      quantidade_triada = this.quantidade_triagem_caminhoes(item, 'caminhoes')
      return quantidade_agendada + quantidade_triada
    },
    peso_atendida(item) {
      let quantidade_triada, quantidade_agendada
      quantidade_agendada = this.quantidade_agendada_total(item, 'peso')
      quantidade_triada = this.peso_triagem_caminhoes(item, 'peso')
      return quantidade_agendada + quantidade_triada
    },
    quantidade_restante_caminhoes_a_distribuir(item) {
      let quantidade_restante, quantidade_distribuida
      quantidade_restante = item.qt_caminhoes
      quantidade_distribuida = this.quantidade_distribuida_caminhoes(item)
      return quantidade_restante - quantidade_distribuida
    },
    quantidade_restante_peso_a_distribuir(item) {
      let peso_restante, peso_distribuida
      peso_restante = item.qt_peso
      peso_distribuida = this.quantidade_distribuida_peso(item)
      return peso_restante - peso_distribuida
    },
    performace_caminhoes(item) {
      let quantidade_restante, quantidade_triada, quantidade_agendada
      quantidade_restante = item.qt_caminhoes
      quantidade_agendada = this.quantidade_agendada(item, 'caminhoes')
      quantidade_triada = this.quantidade_triagem_caminhoes(item, 'caminhoes')
      return (
        (
          ((quantidade_agendada + quantidade_triada) * 100) /
          quantidade_restante
        ).toFixed(2) + ' %'
      )
    },
    performace_caminhoes_peso(item) {
      let quantidade_restante, quantidade_agendada
      quantidade_restante = item.qt_peso
      quantidade_agendada = this.quantidade_agendada(item, 'peso')
      return (
        ((quantidade_agendada * 100) / quantidade_restante).toFixed(2) + ' %'
      )
    },
    quantidade_restante(item, tipo) {
      let quantidade = 0
      if (item) {
        if (tipo == 'peso') {
          quantidade =
            item.qt_peso != 0 ? this.quantidade_restante_peso(item) : 0
        }
        if (tipo == 'caminhoes') {
          quantidade =
            item.qt_caminhoes != 0
              ? this.quantidade_restante_caminhoes(item)
              : 0
        }
      }
      return quantidade
    },
    qt_peso(item) {
      // return item.restante?.qt_peso;
      return this.quantidade_restante(item, 'peso')
    },
    qt_caminhoes(item) {
      // return item.restante?.qt_caminhoes;
      return this.quantidade_restante(item, 'caminhoes')
    },
  },
}

const pesquisa = {
  methods: {
    pesquisa(params) {
      let filtros = {}
      if (params.tipo_operacao) {
        filtros.tipo_operacao = params.tipo_operacao.id
      }
      if (params.fornecedor) {
        filtros.fornecedor = params.fornecedor.public_id
      }
      if (params.produto) {
        filtros.produto = params.produto.id
      }
      if (params.sub_produto) {
        filtros.sub_produto = params.sub_produto.id
      }
      if (params.terminal) {
        filtros.terminal = params.terminal.public_id
      }
      if (params.data_inicio) {
        filtros.data_inicio = params.data_inicio
      }
      if (params.gate) {
        filtros.gate = params.gate.public_id
      }
      if (params.data_fim) {
        filtros.data_fim = params.data_fim
      }
      if (params.tipo_frete) {
        filtros.tipo_frete = params.tipo_frete.id
      }
      if (params.regiao) {
        if (Array.isArray(params.regiao)) {
          filtros.regiao = params.regiao
            .filter((r) => r.public_id)
            .map((r) => r.public_id)
            .toString()
        } else {
          filtros.regiao = params.regiao.public_id
        }
      }
      if (params.cliente) {
        filtros.cliente = params.cliente.public_id
      }
      if (params.transportadora) {
        filtros.transportadora = params.transportadora.public_id
      }
      if (params.tipo ? params.tipo == 'ton' : false) {
        filtros.qt_peso = 1
      }
      if (params.tipo ? params.tipo == 'qtd' : false) {
        filtros.qt_caminhoes = 1
      }
      return filtros
    },
  },
}

const cota_dia_todo = {
  methods: {
    cota_dia_todo(item) {
      if (!item || !item.data_inicio || !item.data_fim) {
        return false
      }
      const data_inicio = new Date(item.data_inicio)
      const data_fim = new Date(item.data_fim)
      const mesmo_dia =
        data_inicio.getFullYear() == data_fim.getFullYear() &&
        data_inicio.getMonth() == data_fim.getMonth() &&
        data_inicio.getDate() == data_fim.getDate()
      const dia_todo =
        data_inicio.getHours() == 0 &&
        data_inicio.getMinutes() == 0 &&
        data_fim.getHours() == 23 &&
        data_fim.getMinutes() == 59
      return mesmo_dia && dia_todo
    },
  },
}
const cota_mesmo_dia = {
  methods: {
    cota_mesmo_dia(item) {
      if (!item || !item.data_inicio || !item.data_fim) {
        return false
      }
      const data_inicio = new Date(item.data_inicio)
      const data_fim = new Date(item.data_fim)
      return (
        data_inicio.getFullYear() == data_fim.getFullYear() &&
        data_inicio.getMonth() == data_fim.getMonth() &&
        data_inicio.getDate() == data_fim.getDate()
      )
    },
  },
}
const mostra_cota_mesmo_dia = {
  methods: {
    mostra_cota_mesmo_dia(item) {
      if (!item || !item.data_inicio || !item.data_fim) {
        return 'Não Definida'
      }
      const data_inicio = new Date(item.data_inicio)
      const data_fim = new Date(item.data_fim)
      const text_day = `${data_inicio.getDate()}`.padStart(2, 0),
        text_month = `${data_inicio.getMonth() + 1}`.padStart(2, 0),
        text_year = `${data_inicio.getFullYear()}`.padStart(4, 0)
      const text_date = `${text_day}/${text_month}/${text_year}`
      const text_hours = (date) => {
        const text_hour = `${date.getHours()}`.padStart(2, 0)
        const text_minutes = `${date.getMinutes()}`.padStart(2, 0)
        const text_seconds = `${date.getSeconds()}`.padStart(2, 0)
        return `${text_hour}:${text_minutes}:${text_seconds}`
      }
      return `${text_date}, ${text_hours(data_inicio)} - ${text_hours(
        data_fim
      )}`
    },
  },
}

export {
  paramsToAgendamento,
  contabilizadores,
  pesquisa,
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
}
