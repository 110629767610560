var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row"},[(_vm.programacoes.headers.length > 0)?[_c('v-switch',{attrs:{"flat":"","label":"Visualizar Resumo"},model:{value:(_vm.resumo),callback:function ($$v) {_vm.resumo=$$v},expression:"resumo"}}),_c('v-switch',{attrs:{"flat":"","label":"Visualizar Detalhes"},model:{value:(_vm.detalhe),callback:function ($$v) {_vm.detalhe=$$v},expression:"detalhe"}})]:_vm._e()],2),_c('v-data-table',{attrs:{"headers":_vm.programacoes.headers,"items":_vm.programacoes.dados,"items-per-page":-1,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.viewRow(item['visivel'], item['info']))?_c('tr',{staticClass:"detail",class:_vm.itemClass(item)},_vm._l((_vm.programacoes.headers),function(col,i){return _c('td',{key:i},[(col.value === 'info')?[(typeof item['info'] === 'object')?_c('div',{staticClass:"cotas-header"},[_c('table',{staticClass:"full-table"},_vm._l((item['info']),function(value,key,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0)]):_c('div',[_vm._v(" "+_vm._s(item['info'])+" ")])]:[(typeof item[col.value] === 'object')?_c('div',[_c('h5',{staticClass:"text-center my-2"},[_vm._v(_vm._s(col.value))]),_vm._l((item[col.value]),function(prog,i){return _c('div',{key:i},[_c('div',{staticClass:"cotas-header"},[_c('h4',[_c('v-icon',{attrs:{"color":Object.keys(prog).length > 0
                          ? prog.SUSPENDIDO
                            ? 'red'
                            : 'green'
                          : 'black'},on:{"click":function($event){return _vm.$emit('detalhesDistribuicao', prog)}}},[_vm._v("mdi-truck")]),_c('span',{staticClass:"cotas-header-text"},[_vm._v("Detalhes")])],1)]),_c('div',{staticClass:"cotas-detalhes",on:{"click":function($event){return _vm.$emit('coletarClickTabela', col.value, prog)}}},[_c('table',{staticClass:"full-table font-8"},_vm._l((prog),function(value,key,i){return _c('tr',{key:i},[(
                          key !== 'PUBLIC_ID' &&
                          key !== 'SUSPENDIDO' &&
                          _vm.inMemoryView[key]
                        )?_c('td',[_vm._v(" "+_vm._s(key)+" ")]):_vm._e(),(
                          key !== 'PUBLIC_ID' &&
                          key !== 'SUSPENDIDO' &&
                          _vm.inMemoryView[key]
                        )?_c('td',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()])}),0)])])})],2):(item['info'] !== '')?_c('div',{staticClass:"text-center",on:{"click":function($event){return _vm.$emit('coletarClickTabela', col.value, item)}}},[_vm._v(" "+_vm._s(item[col.value] || 0)+" ")]):_vm._e()]],2)}),0):_vm._e(),(item.visivel === undefined)?_c('tr',{staticClass:"empty-line"}):_vm._e(),(item.visivel === undefined)?_c('tr',{staticClass:"empty-line"}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" Nenhuma informação obtida na pesquisa ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }